import React, { useEffect, useState } from "react"
import Header from "../components/common/NavBar"

const MainLayout = ({
  children,
  bgChanged = true,
  ppcPageHeader = true,
  schemas,
}) => {
  const [state, setState] = useState(false)

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const handleScroll = () => {
    setState(true)
  }

  const Footer = state
    ? React.lazy(() => import("../components/common/Footer"))
    : null

  return (
    <React.Fragment>
      <Header
        bgChanged={bgChanged}
        ppcPageHeader={ppcPageHeader}
        isScroll={state}
      />
      <main>{children}</main>
      <React.Suspense>
        {Footer && <Footer ppcPageHeader={ppcPageHeader} />}
      </React.Suspense>

      {schemas?.map(v => (
        <script
          defer={true}
          async={true}
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: v.structuredData,
          }}
        />
      ))}
    </React.Fragment>
  )
}

export default MainLayout
